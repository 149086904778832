/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
// import Vue from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import AboutPage from '../views/AboutPage.vue';
import NowPage from '../views/NowPage.vue';
import StyleGuide from '../views/StyleGuide.vue';
import BlogListing from '../views/BlogListing.vue';
import BlogPost from '../views/BlogPost.vue';
import ProjectListing from '../views/ProjectListing.vue';
import ProjectPage from '../views/ProjectPage.vue';
import PortfolioPage from '../views/PortfolioPage.vue';

// Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/now',
    name: 'Now',
    component: NowPage,
  },
  {
    path: '/styleguide',
    name: 'Styleguide',
    component: StyleGuide,
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogListing,
  },
  {
    path: '/blog/:slug',
    name: 'BlogPost',
    component: BlogPost,
  },
  {
    path: '/projects',
    name: 'Projects',
    component: ProjectListing,
  },
  {
    path: '/projects/:slug',
    name: 'Project',
    component: ProjectPage,
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage,
  },
  {
    path: '/portfolio',
    name: 'Portfolio listing',
    component: PortfolioPage,
  },
  {
    path: '/portfolio/:slug',
    name: 'Portfolio',
    component: PortfolioPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    setTimeout(() => { document.getElementById('app').scrollIntoView(); }, 500);

    // if (savedPosition) {
    //   return savedPosition;
    // }
    // return { x: 0, y: 0 };
  },
});

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes,
//   scrollBehavior(to, from, savedPosition) {
//     if (savedPosition) {
//       return savedPosition;
//     }
//     return { x: 0, y: 0 };
//   },
// });

export default router;
