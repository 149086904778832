import { TimelineMax, TweenLite, CSSPlugin } from 'gsap';

// eslint-disable-next-line no-unused-vars
const activated = [
  TimelineMax, TweenLite, CSSPlugin,
];

// gsap.registerPlugin(TimelineMax, TweenLite);

export default class ScrollTween {
  /**
   *
   * Defines a ScrollTween.
   *
   * When the user scrolls, the tween will action whilst
   * {sceneTrigger} is between {sceneStart} and ({sceneStart} + {sceneDuration}).
   *
   * E.g:
   *
   * sceneStart: window.innerHeight would set the tween at the page height.
   * sceneDuration: Count (in pixels) of how long the tween will last as the user scrolls.
   * sceneTrigger: 0 means the top of the screen. Reccommended: window.innerHeight / 2.
   *
   * @param {*} args as documented.
   */
  constructor(args) {
    this.sceneDuration = args.sceneDuration;
    this.sceneTrigger = args.sceneTrigger;
    this.scenes = args.scenes;
    this.debugName = args.debugName;

    if (args.sceneStart) {
      this.sceneStart = args.sceneStart;
    } else if (args.sceneStartElement) {
      const rect = document.querySelector(args.sceneStartElement).getBoundingClientRect();
      this.sceneStart = rect.top;
    }

    if (args.sceneStartOffset) {
      this.sceneStart += args.sceneStartOffset;
    }

    this.timelines = [];

    for (let i = 0; i < this.scenes.length; i += 1) {
      this.timelines[i] = new TimelineMax({
        paused: true,
        repeat: 0,
        yoyo: false,
        onLeave: (self) => self.kill(false, true),
      })
        .to(
          this.scenes[i].targetElement,
          this.sceneDuration,
          this.scenes[i].tweenProperty,
          this.sceneStart,
        );
    }

    if (this.debugName) {
      this.startDiv = document.createElement('div');
      this.startDiv.classList.add('scroll-debug');
      this.startDiv.id = `${this.debugName}Start`;

      this.body = document.querySelector('body');

      this.body.appendChild(this.startDiv);

      this.triggerDiv = document.createElement('div');
      this.triggerDiv.classList.add('scroll-debug');
      this.triggerDiv.id = `${this.debugName}Trigger`;

      this.body.appendChild(this.triggerDiv);

      this.durationDiv = document.createElement('div');
      this.durationDiv.classList.add('scroll-debug');
      this.durationDiv.id = `${this.debugName}Duration`;

      this.body.appendChild(this.durationDiv);
    }
  }

  /**
   *
   * @param {*} idPrepend
   * @param {*} color
   * @param {*} width
   */
  renderDebug(color, width) {
    if (this.startDiv === undefined) {
      return;
    }

    document.getElementById(this.startDiv.id).style.width = width;
    document.getElementById(this.startDiv.id).style.backgroundColor = color;

    document.getElementById(this.triggerDiv.id).style.width = width;
    document.getElementById(this.triggerDiv.id).style.backgroundColor = color;
    document.getElementById(this.triggerDiv.id).style.position = 'fixed';

    document.getElementById(this.durationDiv.id).style.width = width;
    document.getElementById(this.durationDiv.id).style.backgroundColor = color;

    TweenLite.set(`#${this.startDiv.id}`, {
      top: this.sceneStart,
    });

    TweenLite.set(`#${this.triggerDiv.id}`, {
      top: this.sceneTrigger,
    });

    TweenLite.set(`#${this.durationDiv.id}`, {
      top: this.sceneStart + this.sceneDuration,
    });
  }

  /**
   *
   * @param {*} pageYOffset
   */
  update(pageYOffset) {
    for (let i = 0; i < this.timelines.length; i += 1) {
      this.timelines[i].time(pageYOffset + this.sceneTrigger);
    }
  }
}
