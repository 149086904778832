<template hidden>
  <div>

    <h1>Global Styleguide</h1>
    <p>Introductory text paragraph.</p>
    <hr>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore nesciunt consequuntur debitis
    dignissimos doloribus laboriosam aspernatur labore omnis ut ducimus officia eum similique quae
    nobis sed, doloremque magnam at voluptas?</p>

    <!-- eslint-disable-next-line vue/max-attributes-per-line -->
    <p>Irure amet sunt officia sint excepteur. Ut pariatur deserunt ad irure officia ea. Ullamco
      excepteur commodo excepteur tempor.</p>

    <p>Nisi id ex fugiat id aute. Laboris esse laborum irure est id duis ex anim do fugiat laboris.
      Adipisicing sint consequat pariatur esse minim dolor culpa sunt veniam. Magna irure officia
      nulla velit fugiat sint ullamco nulla. Voluptate culpa nisi dolore esse nulla. Lorem nulla ad
      quis anim dolor sunt velit officia amet aliquip deserunt anim cupidatat.</p>

    <p>Cupidatat incididunt nulla voluptate reprehenderit pariatur mollit amet ea esse consectetur
      elit
      qui veniam. Et irure id ea aute labore ullamco duis ea. Labore eiusmod id nulla consequat ad
      do et
      est cillum.</p>

    <p>Sunt aliquip minim aute voluptate id Lorem quis ea ullamco. Adipisicing do ex eiusmod culpa
      amet
      exercitation ea est laboris cupidatat qui sit ipsum. Ut culpa magna tempor velit cillum
      pariatur
      non aute. Labore aliquip magna quis tempor quis voluptate ipsum cillum voluptate.</p>

    <h2>Heading 2</h2>

    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore nesciunt consequuntur debitis
        dignissimos doloribus laboriosam aspernatur labore omnis ut ducimus officia eum similique
        quae
        nobis
        sed, doloremque magnam at voluptas?</p>

    <h3>Heading 3</h3>

    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore nesciunt consequuntur debitis
        dignissimos doloribus laboriosam aspernatur labore omnis ut ducimus officia eum similique
        quae
        nobis
        sed, doloremque magnam at voluptas?</p>

    <h4>Heading 4</h4>

    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore nesciunt consequuntur debitis
        dignissimos doloribus laboriosam aspernatur labore omnis ut ducimus officia eum similique
        quae
        nobis
        sed, doloremque magnam at voluptas?</p>

    <h5>Heading 5</h5>

    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore nesciunt consequuntur debitis
        dignissimos doloribus laboriosam aspernatur labore omnis ut ducimus officia eum similique
        quae
        nobis
        sed, doloremque magnam at voluptas?</p>

    <h6>Heading 6</h6>

    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore nesciunt consequuntur debitis
        dignissimos doloribus laboriosam aspernatur labore omnis ut ducimus officia eum similique
        quae
        nobis
        sed, doloremque magnam at voluptas?</p>

    <hr>
    <h2>Block elements</h2>

    <code><pre>
    #!/bin/null

    /**
    * Comment for a function.
    *
    * @returns int sum of a,b.
    */
    function add(int a, int b) {
        return a + b;
    }

    var blah = add(1, 2);

    </pre></code>

    <blockquote>
        <em>
          It is better to stand on the shoulders of giants unless they have terrible dandruff.
        </em>

        <strong>- Someone, trying to be funny.</strong>
    </blockquote>

    <hr>
    <h2>Imagery</h2>

    <h3>Banner</h3>
    <img class="w-full" src="/media/development-cat-default.jpg" alt="">

    <h3>Single image</h3>

    <figure>
      <img class="w-40 mx-auto" src="/img/icon.png" alt="">
      <figcaption>Image description</figcaption>
    </figure>

    <figure>
      <img
        src="/media/projects/bsp-reader/001bspreader.jpg"
        title="Q3DM11 view from outside the level geometry."
        alt="Q3DM11 view from outside the level geometry."
      >
      <figcaption>
        Q3DM11 view from outside the level geometry.
      </figcaption>
    </figure>

    <h3>Caption gallery</h3>
    <div class="gallery gallery--captioned">
      <figure>
        <span>
          <img src="/media/projects/lunar/thumbs/001GameKit001.gif" alt="">
          <figcaption>The earliest screenshot. Units of different elements would spawn
              from the spheres, and towers would be built on the ledges.
          </figcaption>
        </span>
      </figure>
      <figure>
        <span>
          <img src="/media/projects/lunar/thumbs/002GameKit002.gif" alt="">
          <figcaption>Same, but from the "game camera" (If the building at the back looks at
              all familiar, it's inspired by Gygas' lair from Earthbound.)
          </figcaption>
        </span>
      </figure>
    </div>

    <h3>Basic Gallery</h3>
    <div class="gallery">
      <span><img src="/media/projects/new-reality-engine/thumbs/02terrain.gif" alt=""></span>
      <span><img src="/media/projects/new-reality-engine/thumbs/skydometerrain.gif" alt=""></span>
      <span><img src="/media/projects/new-reality-engine/thumbs/doomrts_tile_and_gui.gif" alt="">
      </span>
      <span><img src="/media/projects/new-reality-engine/thumbs/MD206.gif" alt=""></span>
      <span class="portrait"><img src="/media/projects/lunar/thumbs/020Unity018.gif" alt=""></span>
    </div>

    <section class="section section--blue relative mt-8">
      <font-awesome-icon id="galleryIcon" :icon="['fas', 'camera']" />
      <h3 class="text-white">Spaced Gallery</h3>
      <div class="gallery gallery--spaced">
        <span><img src="http://placekitten.com/300/300" alt=""></span>
        <span><img src="http://placekitten.com/300/300" alt=""></span>
        <span><img src="http://placekitten.com/300/300" alt="">
        </span>
        <span><img src="http://placekitten.com/300/300" alt=""></span>
        <span class="portrait"><img src="http://placekitten.com/200/300" alt=""></span>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'StyleGuide',
  components: {

  },

  title() {
    return 'Styleguide';
  },

  metaCanonical() {
    return window.location.toString().replace(/\/$/, '');
  },

  data() {
    return {
    };
  },

  computed: {
  },

  methods: {

  },
};
</script>

<style lang="scss" style="scoped">

</style>
