<template hidden>
  <aside id="left">
    <div
      class="menu-shadow"
      :class="{'menu-shadow--open': menuVisible}"
      @click="closeMenu"
      @keyup="closeMenu"
    >
    </div>

    <div
      class="menu-gadget flex flex-col items-center justify-center"
      :class="{'menu-gadget--open': gadgetVisible}"
    >
    <div class="w-full">
        <img
          alt="Logo"
          src="../assets/img/logo.svg"
        >
      </div>
      <div class="w-full mt-2 text-center">
        <button class="menu-icon-button" type="button" @click="openMenu">
          <font-awesome-icon
            :icon="['fas', 'bars']"
          />
        </button>
        <!-- <tasty-burger-button
          :active="gadgetButtonActive"
          @toggle="openMenu" /> -->
      </div>
    </div>

    <div
      class="menu-main relative"
      :class="{'menu-main--open': menuVisible}"
      >
      <div class="absolute right-0 mr-2 mt-2">
        <button class="menu-icon-button" type="button" @click="closeMenu">
          <font-awesome-icon
            :icon="['fas', 'times']"
          />
        </button>
        <!-- <tasty-burger-button
        :active="menuButtonActive"
        @toggle="closeMenu" /> -->
      </div>
      <img
        alt="Logo"
        class="logo mt-16 mx-auto w-1/2"
        src="../assets/img/logo.svg"
      >
      <nav id="nav">
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/about">About</router-link></li>
          <li><router-link to="/projects">Side Projects</router-link></li>
          <li><router-link to="/blog/">Blog</router-link></li>
          <li><router-link to="/now">Now</router-link></li>
        </ul>
      </nav>
      <div class="flex">
        <a
          href="http://www.linkedin.com/in/james-edgeworth-bb031623"
          target="_blank"
          class="w-1/4 p-5"
        >
          <img src="/img/social/Linkedin.png" alt="LinkedIn icon"/>
        </a>
        <a
          href="https://twitter.com/j_edgeworth"
          target="_blank"
          class="w-1/4 p-5"
        >
          <img src="/img/social/Twitter.png" alt="Twitter icon"/>
        </a>
        <a
          href="https://instagram.com/j_edgeworth87"
          target="_blank"
          class="w-1/4 p-5"
        >
          <img src="/img/social/Instagram.png" alt="Instagram icon"/>
        </a>
        <a
          href="https://github.com/jedgeworth"
          target="_blank"
          class="w-1/4 p-5"
        >
          <img src="/img/social/Github.png" alt="Github icon"/>
        </a>
      </div>
      <sub class="block mt-6 text-center">&copy; James Edgeworth 2022</sub>
    </div>
  </aside>
</template>

<script>

export default {
  name: 'MenuGadget',

  components: {

  },

  props: {

  },

  data() {
    return {
      gadgetVisible: false,
      menuVisible: false,

      menuIconSize: '4',
    };
  },

  created() {
    this.initMenu();
  },

  methods: {
    /**
     * Initialises the menu gadget animations.
     */
    initMenu() {
      setTimeout(() => {
        this.gadgetVisible = true;
      }, 1000);
    },

    /**
     * Opens the menu by:
     * - Sliding gadget left
     * - Sliding menu right
     */
    openMenu() {
      this.gadgetVisible = false;

      this.gadgetButtonActive = true;

      setTimeout(() => {
        this.menuVisible = true;
      }, 200);
    },

    /**
     * Closes the menu by:
     * - Sliding menu left
     * - Sliding gadget right
     */
    closeMenu() {
      this.menuVisible = false;

      this.menuButtonActive = false;

      setTimeout(() => {
        this.gadgetVisible = true;
      }, 400);
    },
  },
};
</script>

<style lang="scss">

  .menu-icon-button{
    width:3rem;
    height:3rem;
  }

  .menu-shadow{
    z-index:888;
    @apply h-screen;
    @apply bg-black;
    @apply fixed;
    @apply block;
  }

  .menu-gadget{
    @apply w-20;
    @apply bg-white;
    @apply p-4;
    @apply pb-2;
    @apply mt-10;
    @apply z-10;
    @apply fixed;
    @apply border-2;
  }

  .menu-main{
    z-index:999;
    @apply w-64;
    @apply h-screen;
    @apply fixed;
    @apply bg-white;

    .logo{
      max-height:5rem;
    }

    sub{
      @apply bottom-0;
    }
  }

  #nav {

    ul{
      @apply m-0;
      @apply p-0;
      @apply text-center;

      li{
        a{
          @apply relative;
          @apply text-black;
          @apply no-underline;
          @apply font-medium;
          @apply block;
          @apply py-3;

            &:after{ // Animated line under each line
              @apply absolute;
              @apply bg-gray-400;
              @apply block;
              @apply bottom-0;

              height:1px;
              content:"";
            }
        }
      }
    }
  }

  //
  // Animations
  //
  .menu-shadow{
    transition: opacity .2s linear;

    @apply opacity-0;
    @apply w-0;

    &--open{
      @apply opacity-25;
      @apply w-screen;
    }

  }

  //
  .menu-gadget{
    transition: left .2s ease-in-out;

    left:-8rem;

    &--open{
      left:0;
    }
  }

  //
  .menu-main{
    transition: left .4s ease-in-out;

    left:-16rem;

    &--open{
      left:0;
    }
  }

  // Animated lines
  .menu-main #nav ul li a:after {
    transition: width .4s ease-in;
    transition-delay: .6s;

    @apply w-0;

  }
  .menu-main--open #nav ul li a:after {
    @apply w-full;
  }
</style>
