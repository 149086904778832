<template hidden>
  <div>

    <transition name="fade">
      <div v-if="content" v-html="content">
      </div>
    </transition>

    <p>
      <router-link to="/projects/">
        Back to projects
      </router-link>
    </p>

    <modal
      :is-visible="modalVisible"
      :image-data="modalImageData"
      @modal-closed="modalVisible = false"
    />

  </div>
</template>

<script>

import titleMixin from '../mixins/titleMixin';
import modal from '../components/ImageModal.vue';

const axios = require('axios');

export default {
  name: 'ProjectPage',
  components: {
    modal,
  },

  mixins: [
    titleMixin,
  ],

  title() {
    return this.title;
  },

  data() {
    return {
      title: '',
      content: '',

      modalVisible: false,
      modalImageSrc: '',

      modalImageData: {
        title: '',
        src: '',
        caption: '',
      },
    };
  },

  computed: {
  },

  created() {
    window.IM = this.showModal.bind(this);
  },

  mounted() {
    this.loadPage();
  },

  methods: {

    loadPage() {
      axios.get(`/content/projects/${this.$route.params.slug}.htm`)
        .then((response) => {
          let content = response.data;
          content = content.replace(/<img/g, '<img onclick="IM()"');
          this.content = content;

          const titleRegexp = /<h1>(.+)<\/h1>/g;
          const results = titleRegexp.exec(response.data);

          if (results.length > 1) {
            // eslint-disable-next-line prefer-destructuring
            this.title = results[1];
            // this.updateTitle();
            // this.updateCanonical();
          }
        });
    },

    showModal() {
      // eslint-disable-next-line no-restricted-globals
      if (event.srcElement.dataset.fullSrc) {
        // eslint-disable-next-line no-restricted-globals
        this.modalImageData.src = event.srcElement.dataset.fullSrc;
      } else {
        // eslint-disable-next-line no-restricted-globals
        this.modalImageData.src = event.srcElement.src;
      }

      // eslint-disable-next-line no-restricted-globals
      this.modalImageData.title = event.srcElement.title;

      // eslint-disable-next-line no-restricted-globals
      this.modalImageData.caption = event.srcElement.alt;

      this.modalVisible = true;
    },

  },
};
</script>

<style lang="scss" style="scoped">

</style>
