<template hidden>
  <div class="blog">
    <img class="signed" src="/img/signed.png" alt="signature">
    <BlogListingEntry v-for="(post, key) in filteredPosts" :key="key" :post="post">

    </BlogListingEntry>
  </div>
</template>

<script>
import BlogListingEntry from '@/components/BlogListingEntry.vue';
import { posts } from '../entries.json';

export default {
  name: 'BlogListing',
  components: {
    BlogListingEntry,
  },

  title() {
    return 'Blog';
  },

  data() {
    return {
      posts,
    };
  },

  computed: {
    filteredPosts() {
      return this.posts.filter((post) => post.public === true);
    },
  },
};
</script>
<style lang="scss">

  sub {
    bottom: -0.25em;
  }
  .signed{
    @apply mx-auto;
    @apply mb-12;
    @apply mt-0;
    @apply pt-12;
    @apply block;
    @apply max-w-sm;
  }

</style>
