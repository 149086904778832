<template hidden>
  <article>
    <header>
      <h3>{{ post.title }}</h3>
      <sub>{{ post.pubDate }} | {{ post.tags[0] }}</sub>
    </header>
    <em>{{ post.excerpt }}</em>
    <router-link :to="href">Read more</router-link>
  </article>
</template>

<script>

export default {
  name: 'BlogListingEntry',
  components: {

  },

  props: {
    post: Object,
  },

  computed: {
    href() {
      return `/blog/${this.post.slug}`;
    },
  },

  data() {
    return {

    };
  },
};
</script>
<style lang="scss" scoped>
  article{
    @apply my-6;
    @apply pt-6;
    @apply pb-4;
    @apply px-8;
    @apply mx-auto;
    @apply border-t-2;
    @apply max-w-2xl;
    @apply text-center;
  }

  a{
    @apply my-4;
    @apply block;
  }

  h3{
    @apply mb-0;
  }

  header{
    @apply mb-6;
  }

  em{
    @apply block;
  }
</style>
