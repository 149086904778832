<template hidden>
  <div id="app">
    <header>
    </header>
    <main>
      <MenuGadget />
      <section id="main">

        <router-view v-slot="{ Component }">
          <transition name="fade">
            <component :is="Component" />
          </transition>
        </router-view>
      </section>
    </main>
    <footer>
    </footer>
  </div>
</template>

<script>
import MenuGadget from '@/components/MenuGadget.vue';

export default {
  name: 'App',
  components: {
    MenuGadget,
  },

  data() {
    return {
      isMenuVisible: true,
    };
  },

  methods: {

  },
};
</script>

<style lang="scss">

  body{
    font-size:18px;
  }

  main{
    overflow:auto;
  }

  #app {
    @apply text-center;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;

    @screen sm {
      @apply text-left;
    }
  }

  h1,h2,h3,h4,h5,h6{
    font-family: "TeXGyreAdventor",Helvetica,Arial,sans-serif;
  }

  h1{
    @apply font-normal;
    @apply text-5xl;
    @apply text-center;
    @apply my-3;
    @apply mx-2;

    @screen md{
      @apply my-8;
      @apply mx-6;
    }

    border-left: 1px solid #959595;
  }

  h2{
    @apply font-normal;
    @apply text-4xl;
    @apply text-center;
    @apply mt-6;
    @apply mb-4;

    @screen md{
      @apply my-6;
      @apply mx-6;
    }

    //border-left: 1px solid #959595;
  }

  h3{
    @apply font-normal;
    @apply text-3xl;
    @apply text-center;
    @apply mt-6;
    @apply mb-4;
    @apply mx-2;

    @screen md{
      @apply my-6;
      @apply mx-6;
    }
  }

  h4{
    @apply font-normal;
    @apply text-2xl;
    @apply text-center;
    @apply mt-6;
    @apply mb-4;
    @apply mx-2;

    @screen md{
      @apply my-6;
      @apply mx-6;
    }
  }

  h5{
    @apply font-normal;
    @apply text-xl;
    @apply text-center;
    @apply mt-6;
    @apply mb-4;
    @apply mx-2;

    @screen md{
      @apply my-6;
      @apply mx-6;
    }

  }

  h6{
    @apply font-normal;
    @apply text-xl;
    @apply text-center;
    @apply my-2;
    @apply mx-2;

    @screen md{
      @apply my-6;
      @apply mx-6;
    }
  }

  p{
    @apply my-4;
    @apply my-4;

    @screen md{
      @apply my-6;
      @apply mx-8;
    }
  }

  header{
    sup{
      @apply mr-2;
    }
  }

  sup{
    @apply float-right;
    @apply mr-6;
  }

  sub{
    bottom:2.75rem;
  }

  .bordered-l{
    @apply pl-8;
    border-left: 3px solid #959595;
  }

  a{
    @apply text-blue-600;
    @apply underline;
  }

  // h2, h3 {
  //   @apply my-12;
  // }

  hr {
    @apply my-8;

    @screen md{
      @apply my-12;
    }

  }

  p, code, blockquote, ul, ol, dl{
    @apply text-left;
  }

  hr,
  p,
  code,
  blockquote,
  ul,
  ol,
  dl{
    @apply max-w-screen-lg;
    @apply block;
    @apply mx-8;

    @screen lg{
      @apply mx-auto;
    }

  }

  .contained{
    @apply max-w-screen-lg;
    @apply mx-auto;
  }

  figcaption,
  .caption{
    @apply p-4;
    @apply mt-0;
    @apply text-center;
    border:0px solid #ccc;
    background: #f7f7f7;
  }

  .shaded{
    border:0px solid #ccc;
    background: #f7f7f7;
  }

  code{
    font-family:monospace;
    unicode-bidi: embed;
    font-family: monospace;
    white-space: pre;
  }

  ul {
    //@apply mx-8;
    @apply pl-6;
    list-style:disc outside none;

    &.plain{
      list-style:none;
      @apply pl-0;
    }
  }

  ol {
   @apply pl-6;
   list-style-type: decimal;
   list-style-position: inside;
  }

  dl{
    @apply p-4;

    border:1px solid #ccc;
    background: #f7f7f7;

    dt{
      @apply mt-4;
      @apply font-bold;
    }

    dd{
      @apply pl-2;
    }
  }

  blockquote{
    @apply relative;
    @apply p-4;
    @apply mt-0;
    @apply text-center;
    @apply z-10;

    border:0px solid #ccc;
    background: #f7f7f7;

    &:before{
      @apply absolute;
      @apply top-0;
      @apply left-0;
      @apply ml-3;

      z-index:-1;

      content: "\201C";
      font-size: 6rem;
      line-height:6rem;
      color: #ccc;
    }

    &:after{
      @apply absolute;
      @apply top-0;
      @apply right-0;
      @apply mr-3;

      z-index:-1;

      content: "\201D";
      font-size: 6rem;
      line-height:6rem;
      color: #ccc;
    }

    em{
      @apply block;
      @apply mb-4;
    }
  }

  .signed {
    @apply mx-auto;
    @apply block;
    @apply max-w-sm;
    @apply mt-16;
    @apply px-12;

  }

  .gallery{

    @apply flex;
    @apply flex-wrap;

    @apply max-w-screen-lg;
    @apply mx-auto;

    background: #f7f7f7;

    &--captioned{
      background: none;
    }

    figure{
      @apply text-center;
      @apply p-4;
      @apply w-full;
      @apply mb-0;
      @apply mx-0;
      display:block;

      @screen md{
        @apply w-4/12;
      }

      figcaption{
        border:0;
      }

      >span{
        @apply block;
        border:0px solid #ccc;
        background: #f7f7f7;
      }

      img{
        @apply mx-auto;
        @apply w-auto;
      }
    }

    >span{
      @apply mx-auto;
      @apply block;

      @screen md{
        @apply mx-0;
        @apply w-4/12;
      }

      &.portrait{
        min-width:18rem;
        background:#000;
        img{
          @apply w-auto;
        }
      }

      img{
        height:250px;
        @apply mx-auto;
        @apply w-full;
      }
    }

    &--spaced {
      @apply bg-transparent;

      img{
        object-fit: cover;
        cursor:pointer;
      }

      > span {
        display:block;
        padding:1rem;
        cursor:pointer;
        overflow:auto;
      }
    }
  }

  // Single image
  figure{
    @apply mx-auto;
    @apply px-8;
    @apply max-w-screen-lg;
    @apply mb-8;

    @screen lg{
      @apply px-0;
    }

    > img{
      @apply mx-auto;
    }
  }

  // Main section
  section#main{
    @apply p-0;

  }

  // Sections
  .section{
    @apply overflow-auto;

    &--grey{
      background-color: #F3F3F3;
      background-repeat: repeat;
      background-image: url(assets/img/cubes.png);
    }

    &--blue{
      background: url(assets/img/grid-bg.png) #026990;
    }
  }

  .bg-blue-grid{
    background: url(assets/img/grid-bg.png) #026990;
  }

  .scroll-debug{
    position:absolute;
    right:0;
    width:100px;
    height:1px;
    background:red;
    z-index:99999;
  }

  //
  // Transitions
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }

  .scroll-indicator,
  .scroll-indicator:before {
    position: absolute;
    left: 50%;
  }

  .scroll-indicator {
    width: 26px;
    height: 50px;
    margin-left: -13px;
    top: 50%;
    margin-top: -25px;
    border-radius: 15px;
    border:1px solid #888;
  }

  .scroll-indicator:before {
    content: '';
    width: 8px;
    height: 8px;
    background:#888;
    margin-left: -4px;
    top: 8px;
    border-radius: 4px;
    animation: scrollDownFadeOut 1.5s ease-in-out infinite 0s;
  }

  @keyframes scrollDownFadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      transform: translateY(28px);
    }

  }

  @keyframes scrollDownFadeIn {
    0% {
      opacity: 0;
      transform: translateY(-28px);
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }

  }

  @keyframes scrollUpFadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      transform: translateY(-28px);
    }

  }

  @keyframes scrollUpFadeIn {
    0% {
      opacity: 0;
      transform: translateY(28px);
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }

  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

</style>
