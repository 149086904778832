<template hidden>
  <div>
    <section id="triggerStart">
      <div class="intro">
      <div id="shadow"></div>
      <div class="intro-header">
      <h1 class="animated bounceInUp">
        James Edgeworth
        <sub class="animated fadeIn delay-1s">
          Web and Apps
        </sub>
      </h1>

      <div id="scrollLine"></div>
      </div>

      <span id="scrollhint">
        <font-awesome-icon :icon="['fas', 'chevron-down']" />
      </span>
      </div>
    </section>

    <section class="home container mx-auto mb-20">
      <h2 class="mx-8 my-16">Techincal Director of a SaaS Startup</h2>

      <p>
        I used to use this page to showcase my front-end skills having been a
        full-stack freelancer. Time is in short supply these days, so I have
        stripped it back and kept it simple.
      </p>

      <p>
        I lead the technical side of a fantastic Start-up based in Cardiff
        called Sports Injury Fix. We are team who bring the best out of each
        other, and build a platform which the industry has been asking for. It
        has gone better than I anticipated, and faster than I expected thanks
        to the constant drive and determination we have put into it, and it's
        exciting seeing what is still to come.
      </p>

      <p>
        Technology is certainly my thing, and over the course of the years have
        had strong experience in a number of frameworks, libraries, and API's.
        Right now, my favourite is VueJS (to the point I re-wrote this website
        in it), and Symfony. I have a strong interest in Devops, Cloud
        technologies, and in particular Docker and Kubernetes.
      </p>

      <p>
        I (poorly!) maintain a <a href="/blog">blog</a>, and generally don't
        use social media much, though I enjoy mentoring developers, sharing
        experiences with other technical leaders.
      </p>
    </section>
  </div>
</template>

<script>

import Pin from '../pin';
import ScrollTween from '../ScrollTween';

export default {
  name: 'HomePage',
  components: {
  },

  title() {
    return 'Home';
  },

  mounted() {
    let requestId = null;

    // eslint-disable-next-line no-unused-vars
    const introPin = new Pin({
      duration: 2000,
      container: '#triggerStart',
      subject: '.intro',
    });

    // eslint-disable-next-line no-unused-vars
    const lineScene = new ScrollTween({
      // debugName: 'line',
      sceneStart: 0,
      sceneDuration: 1000,
      sceneTrigger: 0,
      scenes: [
        {
          targetElement: '#scrollLine',
          tweenProperty: { width: '100%' },
        },
        {
          targetElement: '#shadow',
          tweenProperty: { background: 'rgba(0,0,0,0.8)' },
        },
      ],

    });

    // Set timeline time to scrollTop
    function update() {
      lineScene.update(window.pageYOffset);
      requestId = null;
    }

    // Only update on animation frames
    window.addEventListener('scroll', () => {
      if (!requestId) {
        requestId = requestAnimationFrame(update);
      }
    });

    update();
  },

};
</script>

<style lang="scss" scoped>

  html, body, section{
    overflow-x:hidden;
  }

  .intro{
    @apply h-screen;
    @apply w-screen;
    @apply flex;

    background:url(../assets/img/front-bg_bw.jpg);
    background-size:cover;

    background-position:center top;

    @screen md{
      background-position:0 0;
    }

  }

  .intro-header{
    @apply relative;
    @apply self-center;

    h1{
      @apply text-white;
      @apply border-l-0;
      @apply ml-8;

      letter-spacing:.7rem;

      sub{
        @apply block;
        @apply mt-4;
        @apply text-3xl;
        @apply bottom-0;
      }
    }
  }

  #scrollhint{
    @apply block;
    @apply absolute;
    @apply text-center;
    @apply bottom-0;
    @apply left-0;
    @apply right-0;
    @apply mb-8;
    @apply text-3xl;

    svg{
      animation: scroll 1.5s ease-in-out infinite 1s;
    }
  }

  @keyframes scroll
  {
    0% {
        opacity: 0;
        color:white;
        transform: translate3d(0,0,0);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        color:black;
        transform: translate3d(0,100%,0);
    }
  }

  section.home{
    @apply relative;
    overflow:hidden;
  }

  .shader{
    @apply w-full;
    @apply h-full;
    @apply text-white;

    background:rgba(0,0,0,0.7);
  }

// Pin styles for intro pin element.
.pin{
  &--container{
    @apply relative;
  }

  &--subject{
    @apply fixed;

    &--unpinned{
      @apply absolute;
      bottom:0px;
    }
  }
}

//
// Tween classes for GSAP.
//
#scrollLine{
  position:absolute;
  left:0;
  bottom:-20px;
  width:0;
  height:2px;
  background:rgba(255,255,255, 0.8);

  .final{
    width:100%;
  }
}

#shadow{
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  height:100%;
  background:rgba(0,0,0, 0);
}

</style>
