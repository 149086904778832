<template hidden>
  <div class="overflow-auto">
    <h1>Projects</h1>

    <p>
      Here is a collection of projects and curiosities i've experimented with over the years.
      Looking at it, you'd wonder why I entered the web industry, but these are side projects, and
      they give a nice variety over doing web projects on the side.
    </p>

    <p>
      My "day job" has always been in web development, whereas I would spend my own time usually
      working on different graphics or game projects. Naturally, client work has to come first, so
      most these projects are incomplete or proof of concepts. I did intend to release "Invasion
      Legacy" and Lunar though, even if they would turn out to be financially unviable.
    </p>

    <p>
      This does not include commercial projects or client work. I still have the source code of all
      these projects, but due to their age, some will not compile. If there's any interest in them,
      then I may open source some of them, though be aware some will be an example of how NOT to
      code.
    </p>

    <article class="project-card">
      <header>
        <sup>Winter 2017</sup>
        <h2>Lunar</h2>
        <sub>Unity, ARkit</sub>
      </header>

      <p>
        There's plenty of tower defence games out there, but none where you play as the actual
        runners.
      </p>

      <p>
        This started to become quite a story-based game with objectives and goals. ARkit was
        announced around this time, and a couple evenings later, the game world was spread along the
        living room floor.
      </p>

      <p>
        This was put on hold (indefinitely) due to time constraints, yet again.
      </p>

      <div class="gallery">
        <span>
          <img
            src="/media/projects/lunar/thumbs/001GameKit001.gif"
            alt="GameKit screenshot"
          >
        </span>
        <span class="portrait">
          <img
            src="/media/projects/lunar/thumbs/020Unity018.gif"
            alt="Unity screenshot"
          >
        </span>
      </div>

      <router-link to="/projects/lunar">
        Read more
      </router-link>
    </article>

    <article class="project-card">
      <header>
        <sup>Spring 2013</sup>
        <h2>EdgeTerrain (OS X)</h2>
        <sub>Objective-C, OpenGL, Cocoa</sub>
      </header>

      <p>
        By this point, I had been in the web industry for three years and always had a graphics
        itch to scratch. The idea was to get the terrain code ported to Objective-C and see it
        running on OS X. It went better than expected.
      </p>

      <p>
        OpenGL was much nicer to use on Mac OS X, and it only took a weekend and a few evenings to
        re-develop the random terrain generator, and add framebuffer objects, shaders, and a few
        other niceties.
      </p>

      <div class="gallery">
        <span>
          <img
            src="/media/projects/edge-terrain/thumbs/001edge-terrain.gif"
            alt="EdgeTerrain (OS X) screenshot"
          >
        </span>
        <span>
          <img
            src="/media/projects/edge-terrain/thumbs/002edge-terrain.gif"
            alt="EdgeTerrain (OS X) screenshot"
          >
        </span>
        <span>
          <img
            src="/media/projects/edge-terrain/thumbs/003edge-terrain.gif"
            alt="EdgeTerrain (OS X) screenshot"
          >
        </span>
      </div>

      <router-link to="/projects/edge-terrain">
        Read more
      </router-link>
    </article>

    <article class="project-card">
      <header>
        <sup>Spring 2012</sup>
        <h2>Invasion Legacy</h2>
        <sub>Cocos2d, Objective-C, iOS</sub>
      </header>

      <p>
        What happens when smart phones are all the rage, and apps are popping onto
        the app store 10 and penny? Bandwagon time..
      </p>

      <p>
        This was effectively going to be a remake of War of the Worlds (PC RTS game) but on
        iOS. It was certainly "getting there", but then freelance projects demanded all my time and
        this got sidelined.
      </p>

      <div class="gallery">
        <span>
          <img
            src="/media/projects/invasion-legacy/thumbs/overworld001.gif"
            alt="Invasion Legacy screenshot"
          >
        </span>
      </div>

      <router-link to="/projects/invasion-legacy">
        Read more
      </router-link>
    </article>

    <article class="project-card">
      <header>
        <sup>Summer 2009</sup>
        <h2>KnightEdit</h2>
        <sub>XNA, C#, HLSL, Xbox 360</sub>
      </header>

      <p>
        A map editor for a Knightlore style game. Inspired loosely by Valve Hammer Editor, progress
        was going well and we started to have a functioning game world (as opposed to a 3D scene
        with somewhat related models).
      </p>

      <div class="gallery">
        <span>
          <img
          src="/media/projects/knight-edit/thumbs/05KnightEdit.gif"
          alt="KightEdit screenshot"
        >
      </span>
      </div>

      <router-link to="/projects/knight-edit">
        Read more
      </router-link>
    </article>

    <article class="project-card">
      <header>
        <sup>Summer 2009</sup>
        <h2>Raycasting</h2>
        <sub>C#.NET</sub>
      </header>

      <p>
        Not much remains of this project, but it certainly scratched an itch. This is raycasting as
        basic as it comes - not nearly Doom or Duke3D, but remeniscent of those ancient maze like
        games you'd get in a computer magazine CD back in the 90's.
      </p>

      <div class="gallery">
        <span>
          <img
            src="/media/projects/raycasting/thumbs/001raycast.gif"
            alt="Raycasting application screenshot"
          >
        </span>
        <span>
            <img
              src="/media/projects/raycasting/thumbs/002raycast.gif"
              alt="Raycasting application screenshot"
            >
        </span>
      </div>

      <router-link to="/projects/raycasting">
        Read more
      </router-link>
    </article>

    <article class="project-card">
      <header>
        <sup>Spring - Autumn 2009</sup>
        <h2>"New Reality" Engine (XNA)</h2>
        <sub>XNA, C#, HLSL, Xbox 360</sub>
      </header>

      <p>
        University was fast coming to a close, so the idea was to find quicker ways to make money
        from games (as opposed to not at all). There were a lot of persuasive articles about XNA
        back then, and for good reason.
      </p>

      <p>
        Compared to C++ and OpenGL (on Windows XP), XNA was absolutely fantastic to work with. It
        was very opinionated which avoided any "should I use x or y method to approach this?" and
        development was much faster as a result. A few rough battlescars from OpenGL certainly
        accelerated that however.
      </p>

      <div class="gallery">
        <span>
          <img
            src="/media/projects/new-reality-engine-xna/thumbs/004xna.gif"
            alt="New Reality Engine (XNA) screenshot of terrain"
        ></span>
        <span>
          <img
            src="/media/projects/new-reality-engine-xna/thumbs/008xna.gif"
            alt="New Reality Engine (XNA) screenshot of terrain"
          >
        </span>
        <span>
          <img
            src="/media/projects/new-reality-engine-xna/thumbs/009xna.gif"
            alt="New Reality Engine (XNA) screenshot of terrain with specular lighting"
          >
        </span>
        <span>
          <img
            src="/media/projects/new-reality-engine-xna/thumbs/010xna.gif"
            alt="New Reality Engine (XNA) screenshot of terrain with more sensible lighting"
          >
        </span>
        <span>
          <img
            src="/media/projects/new-reality-engine-xna/thumbs/013xna.gif"
            alt="New Reality Engine (XNA) screenshot of multitexturing"
          >
        </span>
      </div>

      <router-link to="/projects/new-reality-engine-xna">
        Read more
      </router-link>
    </article>

    <article class="project-card">
      <header>
        <sup>Spring 2008 - Summer 2009</sup>
        <h2>"New Reality" Engine</h2>
        <sub>C++, OpenGL, Win32, GLSL</sub>
      </header>

      <p>
        Back during the university days, I got hooked on graphics engine programming. My
        dissertation ended up being on Procedural Terrain Generation.
      </p>
      <p>
        The idea for a few of us was to build games which we wanted to see resurrected, or
        to turn existing games into something else. Ideas included:
      </p>
      <ul>
        <li>DoomRTS (real-time strategy game using Doom assets).</li>
        <li>Magic Carpet (A multiplayer/skirmish, modern version of Magic Carpet 2).</li>
        <li>"Knightlore" (A modern version of the old Knightlore style games).</li>
      </ul>

      <p>
        We worked insane hours on our own individual versions of the engine and trying to work out
        how to do certain concepts, but ultimately the university workload got in the way.
      </p>

      <p>
        Below are the the various concepts done on my version, and anything perhaps
        interesting about them. The engine was originally written on Windows XP with OpenGL, but
        fragments were later ported to XNA (for Xbox 360) and much later on, I ported some of the
        terrain code to MacOS.
      </p>

      <p><em>I'll be first to admit - "New Reality" is a rather... early statement to be making for
        this, and "engine" is being optimistic..
      </em></p>

      <div class="gallery">
        <span>
          <img
            src="/media/projects/new-reality-engine/thumbs/02terrain.gif"
            alt="New Reality Engine screenshot of terrain"
          ></span>
        <span>
            <img
              src="/media/projects/new-reality-engine/thumbs/skydometerrain.gif"
              alt="New Reality Engine screenshot of skydome"
            ></span>
        <span>
          <img
            src="/media/projects/new-reality-engine/thumbs/doomrts_tile_and_gui.gif"
            alt="New Reality Engine screenshot DoomRTS"
          ></span>
        <span>
          <img
            src="/media/projects/new-reality-engine/thumbs/MD206.gif"
            alt="New Reality Engine screenshot of MD2 model"
          ></span>
      </div>

      <router-link to="/projects/new-reality-engine">
        Read more
      </router-link>
    </article>

    <article class="project-card">
      <header>
        <sup>Spring 2008</sup>
        <h2>Quake BSP reader</h2>
        <sub>OpenGL, Win32, C++</sub>
      </header>

      <p>
        This reads the raw data of a Quake BSP map file, and renders its geometry.
      </p>
      <p>
        This was a method of figuring out why things were done the way they are and was certainly a
        learning exercise.
      </p>

      <div class="gallery">
        <span><img src="/media/projects/bsp-reader/thumbs/001bspreader.gif" alt="BSP reader"></span>
      </div>

      <router-link to="/projects/bsp-reader">
        Read more
      </router-link>
    </article>

    <article class="project-card">
      <header>
        <sup>Spring 2004 - Spring 2014</sup>
        <h2>Black0ps.com</h2>
        <sub>PHP4/5, phpBB2, phpBB3</sub>
      </header>

      <p>
        Black0ps was a small online community of developers and gamers. Forums were "the thing"
        back then, but we had a different approach where we would manually promote members up the
        ranks, and this turned out to build a very tight-knit community.
      </p>

      <p>
        Many of the members have met each other in person, often across continents, and remain
        good friends to this day.
      </p>

      <router-link to="/projects/black0ps">
        Read more
      </router-link>
    </article>

  </div>
</template>

<script>

export default {
  name: 'ProjectListing',
  components: {

  },

  title() {
    return 'Projects';
  },

  data() {
    return {
    };
  },

  computed: {
  },

  methods: {

  },
};
</script>

<style lang="scss" style="scoped">

.project-card{

  @apply my-24;
  @apply pt-0;
  @apply overflow-auto;

  @apply max-w-screen-lg;
  @apply mx-8;

  border-top:1px solid #ccc;

  @screen lg{
    @apply mx-auto;
  }

  p{
    @apply mx-0;

    @screen lg{
      //@apply mx-8;
    }
  }

  header{
    @apply relative;

    h2{
      @apply pt-2;
    }

    sup{
      @apply absolute;
      @apply top-0;
      @apply right-0;

      margin-top:-.5rem;
    }

    sub{
      @apply absolute;
      @apply top-0;
      @apply left-0;

      margin-top:-.5rem;
    }
  }

  >a{
    @apply mt-4;
    @apply float-right;
    @apply font-bold;
  }
}

</style>
