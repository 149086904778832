<template hidden>
  <div class="about">

    <section class="section section--grey pt-8 relative">
      <font-awesome-icon
        class="absolute bottom-0 left-0 ml-8 mb-8 text-6xl hidden md:block"
        :icon="['fas', 'quote-left']"
        />
      <div class="bg-white pt-8 md:p-8 md:ml-64">
        <h1>About</h1>

        <p>A full-stack Web Developer who enjoys challenging projects.</p>

        <p>I built and maintained a number of projects on the side prior to being full-time in the
          industry - <a target="_blank" href="/projects/black0ps">the first launched in 2004</a>.
        </p>

        <p>My first role in the industry was in late 2009 primarily as a back-end developer who
          would implement the bespoke features into projects. This would by extension require
          front-end development to the basic standards we had back in 2009.</p>

        <p>Twelve years on, and whilst back-end development has been my core skill, it has required
          front-end development to ever-increasing and far more interesting levels. I enjoy looking
          at award-winning websites and themes and pulling them apart. There is an addictive quality
          to the nature of front-end development which produces visible results. Some of this is
          likely driven by my earlier interest in graphics programming and game development.</p>

        <p>I take a positive, coaching approach to training and supporting developers.
          I am modest in person, and believe that although I have a lot to give, others still have
          a lot they can teach me.</p>
      </div>
    </section>

    <section class="section section--blue pb-16 md:py-16 px-6 md:px-16">
      <h2 class="text-white mb-2 mt-6 md:mb-6">Tech Stack</h2>

      <div class="flex flex-wrap">
        <div class="tech-card w-full md:w-1/2">
          <span>
            <h3>Favourites</h3>
            <i>"If starting from new, I would use"</i>
            <ul>
              <li><b>Backend:</b> Laravel (for MVP), Symfony for long-term.</li>
              <li><b>Frontend:</b> VueJS, Tailwind.</li>
              <li><b>E2E testing:</b> Cypress.</li>
              <li><b>App:</b> Vue.js or React Native (as a Progressive Web App).</li>
              <li><b>Version control:</b> Git (who wouldn't..)</li>
            </ul>
          </span>
        </div>
        <div class="tech-card w-full md:w-1/2">
          <span>
            <h3>Strong Experience With:</h3>
            <i>Used commercially, extensively.</i>
            <ul>
              <li>PHP (18 years)</li>
              <li>MySQL (18 years)</li>
              <li>CSS (18 years)</li>
              <li>Javascript (12 years, as ES6: 6 years)</li>
              <li>jQuery (12 years)</li>
              <li>Symfony (10 years)</li>
              <li>Wordpress (6 years)</li>
              <li>SASS/LESS (7 years)</li>
              <li>Objective-C (3 years)</li>
              <li>Unity (3 years)</li>
              <li>Vue.js (2 years)</li>
              <li>Swift (1 year)</li>
            </ul>
          </span>
        </div>
        <div class="tech-card w-full md:w-1/2">
          <span>
            <h3>Good Experience With:</h3>
            <i>Used either commercially, or significantly in side projects.</i>
            <ul>
              <li>Docker (3 years)</li>
              <li>Kubernetes (3 years)</li>
              <li>AWS, Google Cloud Platform (3 years)</li>
              <li>Laravel (2 years)</li>
              <li>C++, OpenGL, GLSL (2 years)</li>
              <li>C#.NET (&lt; 1 year)</li>
              <li>MSSQL (&lt; 1 year)</li>
            </ul>
          </span>
        </div>
        <div class="tech-card w-full md:w-1/2">
          <span>
            <h3>Toyed With:</h3>
            <i>
              Built something with, and got comfortable with it, but didn't take much further than
              that.
            </i>
            <ul>
              <li>DirectX, HLSL, C# XNA (&lt; 1 year)</li>
              <li>React Native (&lt; 1 year)</li>
              <li>WebGL (&lt; 1 year)</li>
              <li>Augmented Reality (build AR functionality into an existing Unity game)
                (1 week)</li>
            </ul>
          </span>
        </div>
      </div>
    </section>

    <section class="section px-0 md:px-8 md:py-16 md:px-16">
        <h2 class="mb-2 mt-6 md:mb-6">In-house Experience</h2>

        <hr>

        <h3>SIF Health (Technical Director, 3.5 years)</h3>

        <h4 class="md:text-left md:ml-0">Role Highlights:</h4>

        <ul class="">
          <li>The technical perspective on the board of directors.</li>
          <li>Designing, planning, and deploying the technical infrastructure of the company.</li>
          <li>Running Agile (using SCRUM) using strict practices as the lead developer. Negotiating
            with the product owner and business lead to determine the focus of the next sprint.
            Assuring (where possible) that the tech team meets the sprint commitment.</li>
          <li>Developing a fully functional booking and notes platform used by thousands of
            therapists.</li>
          <li>Setting up and being part of the various company events, such as the National Running
            Show, BodyFit Expo, RunFit Expo.</li>
          <li>Mentoring and coaching developers.</li>
          <li>Working with cyber-security experts to ensure we meet Cyber Essentials certification
            with the goal of reaching Cyber Essentials +.</li>
        </ul>

        <p>The tech side and business side operated on the idea of "healthy tension" - if we are
          agreeing on everything then one side is redundant. The tech side operated as a voice of
          reason versus the wishes and ambitions of the business side and often became a "do we
          prioritise the stability of the system over the opportunity to reach another 2000
          potential users this week".</p>

        <p>Attending events such as the National Running Show was certainly out of my comfort zone -
          my role in such was
          purposely front-of-house, taking payments and bookings for people visiting the stall to
          get treatment from various practitioners who helped on the stall. The intention was that
          whilst being a technical director, it was important to ground myself in the everyday
          experiences of customers and therapists - by extension, our users could see and meet the
          people responsible for the platform.
          </p>

        <p>Being on the board of directors was certainly a step up and formality above what I was
          expecting but was enjoyable and served as a fantastic big-picture review of where we are
          and where we intend to go. Technical discussion was uncommon, with feedback being because
          the tech side rarely required a discussion and the goals facing the
          board were mostly to do with business opportunity.
        </p>

        <p>MMR and ARR was steady but slow, whilst churn-rate was exceptionally low (a good thing).
          Ultimately though, the industry we were servicing was severely impacted by Covid lockdowns
          which prevented our income for nearly 1 1/2 years. During more optimistic times, the
          growth was there, it was just too late. Statistically speaking, if Covid hadn't arrived,
          we would have had the revenue required to keep us afloat and growing.
        </p>

        <h4 class="md:text-left md:ml-0">Technical Highlights:</h4>
        <ul>
          <li>Production, staging, beta ran on Kubernetes. I researched how to setup and run a
            cluster and how to diagnose and resolve ongoing issues.</li>
          <li>The dev environments ran on Docker, perhaps for obvious reasons.</li>
          <li>Builds and deployments were handled via a custom bash script on a simple instance.
            The server would poll the git repository for main, staging, and beta branches, would
            build the Docker image, and email us to let us know if it completes successfully or had
            issues. Intention was to move it to Google Cloud Build, but there were always more
            urgent priorities.</li>
          <li>Static code analysis, unit testing was handled during dev and was part of the process
            prior to code reviews each sprint.</li>
          <li>Migrated the project from Twig/JQuery/CSS to VueJS/Tailwind.</li>
          <li>Upgraded the project from Symfony 3 to 4.4 with minimal issues.*</li>
        </ul>

        <p><i>*The importance of this point is to demonstrate that despite the fast-paced
          development
          lifecycle of a startup, and the fact the project was originally built by a web agency,
          we were able to prevent the codebase accumilating crippling tech debt. This upgrade
          required porting to a new codebase and strictly using Symfony 4's requirements, and
          identifying and removing legacy code which otherwise prevented the natural upgrade.
          </i></p>

        <p>Kubernetes is overkill for what we needed though we had a generous amount of credit from
          Google and took the opportunity to learn the technology without financially impacting
          the company. There are times where the system would have gone down if it wasn't for
          Kubernetes - it auto-recovered from a number of minor blips due to the production
          environment horizontally scaling. There was the occasional time we needed to manually
          cordon and drain nodes to allow the cluster to spin up replacements but ultimately we were
          glad of the decision to go overkill in this case.</p>

    </section>

    <section class="section section--grey md:px-8 md:py-16 md:px-16">
        <div class=" md:mx-8 xl:mx-64 md:px-6">
          <h2>Freelance and Agency Experience</h2>
          <p>
            Previous contracts prevent me listing a lot of projects on my portfolio, nor can I
            demonstrate them without permission. I tend to demonstrate my worth
            when discussing client projects and how we can build and solve the
            business needs. My experience covers:
          </p>

          <div class="bg-white py-8 md:p-8 border-2">
            <ul>
              <li>Crowdfunding platforms.</li>
              <li>
                Custom e-commerce (Symfony) including stocks and sales management with Amazon and
                Ebay.
              </li>
              <li>Sports team and membership management.</li>
              <li>Javascript/HTML Canvas custom graphing tools.</li>
              <li>Cross-platorm and native iOS apps.</li>
              <li>Social networking platforms.</li>
              <li>Oauth2 APIs.</li>
              <li>CRMs and HR Management platforms.</li>
              <li>B2B iOS apps.</li>
              <li>Countless Wordpress and Opencart websites.</li>
            </ul>
          </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'AboutPage',
  components: {
  },

  title() {
    return 'About';
  },

  mounted() {

  },
};
</script>

<style lang="scss" scoped>

h3{
  //@apply text-xl;
}

h4{
  @apply mx-auto;
  @apply max-w-screen-lg;
}

.fa-quote-left{
  width:150px;
  height:150px;
  opacity:20%;
}

.tech-card{

  @apply mt-4;

  @screen md{
    @apply mt-6;
  }

  >span {
    @apply block;
    @apply bg-white;
    @apply relative;
    @apply p-6;
    @apply mx-2;
    @apply w-full;

    @screen md {
      @apply w-auto;
      @apply p-8;
    }

    @screen sm {
      height:38rem;
    }

    h3{
      @apply my-2;

      @screen md{
        @apply my-6;
      }
    }

    ul{
      @apply mt-4;
      @apply mx-0;
    }

  }
}

</style>
