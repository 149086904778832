<template hidden>
  <div>

    <transition name="fade">
      <div
        id="triggerStart"
        class="viewport"
        :class="{'expanded':navigationState != 0}"
      >
        <div
          id="navLeft"
          @click="handleNavigate(1)"
          @keyup="handleNavigate(1)"
          :class="{'visible': navigationState == 0, 'alt': project.navStyleAlt}"
        >
          <font-awesome-icon :icon="['fas', 'chevron-left']" />
        </div>

        <div
          id="navRight"
          :class="{'visible': navigationState == 1, 'alt': project.navStyleAlt}"
          @click="handleNavigate(0)"
          @keyup="handleNavigate(0)"
        >
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </div>

        <div
          id="navDown"
          :class="{'visible': navigationState == 1}"
          @click="handleNavigate(1)"
          @keyup="handleNavigate(1)"
        >
          <span class='scroll-indicator'></span>
        </div>

        <section
          class="hero"
          :class="{'navigated': navigationState == 1}"
        >

          <img
            class="main"
            :class="{
              'navigated': navigationState == 1,
              'trigger-out': activeImageBuffer === 2,
              'trigger-in': activeImageBuffer === 1
            }"
            :src="imageBuffer1Src"
            alt="hero image"
          />

          <img
            class="main"
            :class="{
              'navigated': navigationState == 1,
              'trigger-out': activeImageBuffer === 1,
              'trigger-in': activeImageBuffer === 2
            }"
            :src="imageBuffer2Src"
            alt="hero image"
          />

          <div
            class="intro flex items-center"
            :class="{'visible': navigationState == 0}"
          >
            <div class="w-full">
              <div
                class="portfolio-card"
                :class="{
                  'trigger-out': portfolioTrigger === 1,
                  'trigger-in': portfolioTrigger === 2
                }"
              >
                <h1>{{ project.title }}</h1>

                <p>{{ project.intro }}</p>

                <ul>
                  <li>Date: {{ project.date }}</li>
                  <li>Type: {{ project.type }}</li>
                  <li>Role: {{ project.role }}</li>
                  <li>Client: {{ project.client }}</li>
                </ul>
              </div>

              <div class="flex items-center mx-auto" style="max-width:200px">
                <span class="w-1/3">
                  <button
                    @click="navigateProject(0)"
                  >
                    <font-awesome-icon :icon="['fas', 'chevron-up']" />
                  </button>
                </span>

                <span class="w-1/3">
                  {{ parseInt(projectIndex) +1 }} / {{ countProjects }}
                </span>

                <span class="w-1/3">
                  <button
                    @click="navigateProject(1)"
                  >
                    <font-awesome-icon :icon="['fas', 'chevron-down']" />
                  </button>
                </span>
              </div>
            </div>
          </div>

          <div
            class="detail flex items-center"
            :class="{'visible': navigationState == 1}"
          >
            <div v-html="project.summaryHtml">
            </div>
          </div>
        </section>

        <article
          class="more"
          :class="{'visible': moreContainerVisible == true}"
        >
          <hr>
          <section class="mb-12 px-4 md:px-8">
            <h2>{{ project.headline }}</h2>

            <span v-html="project.headlineDescriptionHtml"></span>

          </section>

          <section
            v-if="project.listing"
            id="statistics"
            class="section section--grey px-4 py-6 md:p-12"
          >

            <h3>The statistics</h3>
            <div class="container mx-auto flex">
              <ul id="statsList1" class="w-full md:w-1/2">
                <li
                  v-for="(listItem, key) in project.listing.list1"
                  :key="key"
                >
                  {{ listItem }}
                </li>
              </ul>

              <ul id="statsList2" class="w-full md:w-1/2">
                <li
                  v-for="(listItem, key) in project.listing.list2"
                  :key="key"
                >
                  {{ listItem }}
                </li>
              </ul>
            </div>
          </section>

          <section
            v-if="project.imagePairings[0] !== undefined"
            id="imagePairing1"
            class="container image-pairing image-pairing--mbp"

          >
            <div>
              <span>
                <h3>{{ project.imagePairings[0].title }}</h3>
                <div v-html="project.imagePairings[0].descriptionHtml"></div>
              </span>

              <img
                :src="project.imagePairings[0].image"
                :class="{'non-device': !project.imagePairings[0].imageIsDevice}"
                alt="Device image"
              />
            </div>
          </section>

          <section
            v-if="project.imagePairings[1] !== undefined"
            id="imagePairing2"
            class="container image-pairing image-pairing--iphone"
          >
            <div>
              <span>
                <h3>{{ project.imagePairings[1].title }}</h3>
                <div v-html="project.imagePairings[1].descriptionHtml"></div>
              </span>
              <img
                :src="project.imagePairings[1].image"
                :class="{'non-device': !project.imagePairings[1].imageIsDevice}"
                alt="Device image"
              />
            </div>
          </section>

          <section
            v-if="project.imagePairings[2] !== undefined"
            id="imagePairing3"
            class="container image-pairing image-pairing--ipad"
          >
            <div>
              <span>
                <h3>{{ project.imagePairings[2].title }}</h3>
                <div v-html="project.imagePairings[2].descriptionHtml"></div>
              </span>

              <img
                :src="project.imagePairings[2].image"
                :class="{'non-device': !project.imagePairings[2].imageIsDevice}"
                alt="Device image"
              />
            </div>
          </section>

          <section
            v-if="project.takeaways !== undefined"
            class="section section--grey relative"
          >
          <font-awesome-icon
            class="absolute top-0 left-0 ml-8 mt-8 text-6xl hidden md:block"
            :icon="['fas', 'quote-left']"
          />
            <h2>Key takeaways</h2>

            <ol>
              <li
                v-for="(takeaway, key) in project.takeaways"
                :key="key"
              >{{ takeaway }}</li>
            </ol>
          </section>

          <section class="section section--blue relative">
            <font-awesome-icon id="galleryIcon" :icon="['fas', 'camera']" />

            <h2 class="text-white">Stills</h2>

            <div class="gallery gallery--spaced mb-24">
              <span
                v-for="(image, key) in project.galleryItems"
                :key="key"

              >
                <img
                  :src="image"
                  alt="Gallery image"
                  @click="showImageModal(image)"
                  @keyup="showImageModal(image)"
                />
              </span>
            </div>
          </section>

          <img
            id="final"
            class="block mx-auto"
            :class="{'hidden': !project.finalImage}"
            :src="project.finalImage"
            alt="final overview image"
          />
          <img class="signed" src="/img/signed.png" alt="signature">

        </article>
      </div>
    </transition>

    <modal
      :is-visible="modalVisible"
      :image-data="modalImageData"
      @modal-closed="modalVisible = false"
    />
  </div>
</template>

<script>

import titleMixin from '../mixins/titleMixin';
import ScrollTween from '../ScrollTween';
import IntersectionBinding from '../intersectionBinding';
import modal from '../components/ImageModal.vue';

export default {
  name: 'PortfolioPage',
  components: {
    modal,
  },

  mixins: [
    titleMixin,
  ],

  title() {
    if (this.navigationState === 1) {
      return `Portfolio: ${this.project.title}`;
    }

    return 'Portfolio Listing';
  },

  data() {
    return {
      // title: 'Sample portfolio page',
      content: '',

      modalVisible: false,
      modalImageSrc: '',

      modalImageData: {
        title: '',
        src: '',
        caption: '',
      },

      /**
       * 0: Intro title and text. Hero image cut off to left.
       * 1: Detail text. Hero image cut off to right. Page scrolling enabled.
       */
      navigationState: 0,
      moreContainerVisible: false,

      projectIndex: 0,

      // If buffer 1 active, set buffer 2 src to next project image.
      // Set fadeOut on buffer 1, fadeIn on buffer2 onload.
      activeImageBuffer: 1,
      imageBuffer1Src: '',
      imageBuffer2Src: '',

      imageTrigger: 0,
      portfolioTrigger: 0,
      portfolioTriggerDirection: 0,

      items: [
        {
          slug: 'sif',
          heroImage: '/media/portfolio/sif/home-xl.png',
          navStyleAlt: true,
          title: 'SIF Health',
          intro: 'Enabling people to find the right treatment.',
          date: 'February 2018 - March 2022',
          type: 'Bespoke web, full time',
          role: 'Technical Director',
          client: 'SIF Health',

          summaryHtml: '<p>Implementing a booking system into a directory listing of sports therapists grouped by location, injury specialisms, and treatment types.</p><p>In order to get initial funding, they needed a dedicated Technical Director. I saw an interesting, long-term challenge so accepted the role.</p>',

          headline: 'Working within the industry, to the requests of the industry',
          headlineDescriptionHtml: '<p>There are a number of booking platforms but feedback was that none seemed specific to what therapists wanted. We were the first (at the time) to facilitate online payments for bookings so patients can book directly with the therapists ahead of time. Our notes system and calendaring functionality was comprehensive, but specific.</p><p>We attended the National Running Show, Run-fit Expo, and BodyFit Expo as front-of-house staff so therapists could meet us and know who is behind the project.</p>',

          listing: {
            title: 'The Statistics',
            list1: [
              '> 5500 therapists',
              '> 430,000 appointments',
              '> 327,000 patient notes',
            ],
            list2: [
              '3 national industry events attended (personally)',
              '11 team members',
              '< 1 hour total downtime over 3.5 years',
            ],
          },

          imagePairings: [
            {
              title: 'Stability',
              descriptionHtml: '<p>Initially the project was built as a listing website by a web agency. Part of my remit involved identifying and cleaning up legacy code and technical debt as functionality evolved.</p><p>Upgrading from Symfony 3.4 to 4.4 was not straightforward but proved the longevity of the codebase.</p>',
              image: '/media/portfolio/sif/pairing-mbp.png',
              imageIsDevice: true,
            },
            {
              title: 'security',
              descriptionHtml: '<p>We had no breaches, even from professional pen-testers.</p><p>I build all back-end functionality assuming the user is going to try to break it, or break in. Our record is clean.</p>',
              image: '/media/portfolio/sif/pairing-iphone.png',
              imageIsDevice: true,
            },
            {
              title: 'Roadmaps, building and iteration',
              descriptionHtml: '<p>We had our own ideas for the features but prioritised those being requested by our users. We were public with this, and customers enjoyed our response times to their queries and really stood behind us.</p><p>In return? We felt valued by the industry and enjoyed building the features people wanted.</p>',
              image: '/media/portfolio/sif/pairing-ipad.png',
              imageIsDevice: true,
            },
          ],

          takeaways: [
            "Working on the same codebase for a number of years brings about challenges you don't anticipate from agency projects. You will notice and appreciate the code optimisations, likewise the accumilating technical debts.",
            "The roadmap will grow much faster than anticipated. There's no time to dedicate to making optimisations and removing tech debt unless you specifically make the time, and enforce the importance of this.",
            'Each person in the organisation had features they really want to see, likewise the developers have improvements they really want to focus on. We rarely agreed on what these were, but compromised on them based on the industry outlook vs what technical fixes are a must.',
            "The hardest dev work was in re-factoring the data structure to facilitate new functionality which we couldn't realistically foresee earlier on. I wrote migration scripts which once run on live, there's no coming back. There were no catastrophes and i'm honestly surprised by that considering the sheer stress of the task.",
            'Deciding on the switch from jQuery to VueJS took a lot of self-persuading. It meant some late nights building a Symfony-to-VueJS form adapter which took longer than anticipated but sped up frontend development, and made the codebase far more interesting to work with. It was a gamble that felt wrong, but proved itself worth it in every way.',
          ],

          galleryItems: [
            '/media/portfolio/sif/blog-xxl.png',
            '/media/portfolio/sif/home-xxl.png',
            '/media/portfolio/sif/booking-xxl.png',
            '/media/portfolio/sif/calendar-xxl.png',
            '/media/portfolio/sif/class-xxl.png',
            '/media/portfolio/sif/domain-xxl.png',
            '/media/portfolio/sif/integration-md-v.png',
            '/media/portfolio/sif/invoice-xxl.png',
            '/media/portfolio/sif/invoices-xxl.png',
            '/media/portfolio/sif/note-xxl.png',
            '/media/portfolio/sif/report-xxl.png',
            '/media/portfolio/sif/rota-xxl.png',
            '/media/portfolio/sif/search-xxl.png',
            '/media/portfolio/sif/specialists-xxl.png',
          ],

          finalImage: '/media/portfolio/sif/final.png',
        },

        {
          slug: 'pocketwriters',
          heroImage: '/media/portfolio/pocket-writers/header.png',
          navStyleAlt: true,
          title: 'Pocket Writers',
          intro: 'Enabling anyone to be an author from their pocket.',
          date: 'Late 2012',
          type: 'Native iOS, Android, Web API',
          role: 'Freelance Backend Developer',
          client: 'Peapple Ltd',

          summaryHtml: '<p>PocketWriters enabled users to write fan fiction in the palm of their hand. With an intricately-designed app, and a water-tight Oauth2 API, tens of thousands of users signed up, and still used it nearly a decade later.</p><p>Development involved strong interaction between the app and API developers, and involved a highly skilled designer for its vast collection of effects and graphics.</p>',

          headline: 'Tens of thousands of users. Millions of requests per month.',
          headlineDescriptionHtml: '<p>Barely a marketing budget - the growth of this app was unexpected. Word-of-mouth mixed with collaboration opportunities for the users saw the app grow at a surprising rate.</p><p>My involvement was on the server-side API building the functionality to support the apps.</p>',

          statistics: {
            title: 'The Statistics',
            list1: [
              '> 250,000 stories',
              '> 2,000,000 chapters',
              '> 120,000 users',
            ],
          },

          imagePairings: [
            {
              title: 'Allowing writers to share ideas, right within the context of their story.',
              descriptionHtml: '<p>Users could start their own books, consisting of chapters, and could even collaborate with other users. Feedback could be given on each chapter, allowing users the potential to write their own novels knowing that they have already passed public opinion.</p>',
              image: '/media/portfolio/pocket-writers/new-story.png',
              imageIsDevice: false,
            },
            {
              title: 'Intricately old-fashioned',
              descriptionHtml: '<p>The design is clearly skuomorphic, because it was written before Jony Ive brought us iOS 5, which overhauled the design paradigm.</p>',
              image: '/media/portfolio/pocket-writers/search.png',
              imageIsDevice: false,
            },
            {
              title: 'Oauth2',
              descriptionHtml: '<p>The API was built using Codeigniter. Not my choice these days but the framework is simple, and quick to get going with, and certainly proved itself.</p>',
              image: '/media/portfolio/pocket-writers/categories.png',
              imageIsDevice: false,
            },
          ],

          takeaways: [
            'Skeomorphism was impressive at the time and the design budget was serious. Getting all the effects and graphics in place, and UX interactions with the limits of Objective-C took longer than building the actual app and API functionality. It seemed a good idea at the time until the industry very quickly shifted to flat design, making the app look very old fashioned.',
            'I started re-writing the API in Lithium (#li3) framework. The idea was to scaffold the existing API with functional tests and then build the new version to get the same results. It was a waste of time, even more-so with that framework being discontinued not long after.',
            'The app and API was barely updated in years following 2013, and yet people carried on using it at a notable pace. It was easy to see development had stopped due to the design but the system did not suffer any security breaches despite the longevity and lack of development investment.',
          ],

          galleryItems: [
            '/media/portfolio/pocket-writers/home.png',
            '/media/portfolio/pocket-writers/editors-picks.png',
            '/media/portfolio/pocket-writers/leaf-board.png',
            '/media/portfolio/pocket-writers/new-story.png',
            '/media/portfolio/pocket-writers/search.png',
            '/media/portfolio/pocket-writers/settings.png',
          ],

          // finalImage: '',
        },

        {
          slug: 'thehumbleabode',
          heroImage: '/media/portfolio/the-humble-abode/header.png',
          navStyleAlt: false,
          title: 'The Humble Abode',
          intro: 'Glamping directory',
          date: 'Early 2016',
          type: 'Wordpress bespoke',
          role: 'Freelance Developer',
          client: 'Humble Abode Glamping Ltd',

          summaryHtml: '<p>An attempt to break into the acommodation industry saw the creation of The Humble Abode. Instead of customers looking to stay in people&#39;s homes, it was all about Glamping, or glamarous camping.</p>',

          headline: 'Minimum Viable Product To Test The Market',
          headlineDescriptionHtml: '<p>The website features a comprehensive booking process, allowing the supplier some flexibility on their acommodation, such as:</p>'
            + '<ul>'
            + '<li>per-date pricing allowing for peak holiday seasons to have a premium price</li>'
            + '<li>Additional costs based on number of users</li>'
            + '<li>Ability to book multiple &#39;rooms&#39; for parties of people</li>'
            + '<li>Ability to define their own extra (such as evening camp fires)</li>'
            + '<li>Ability to specify booking in and checking out times</li>'
            + '</ul>'
            + '<p>Booking was simple and safe. The customer confirms the booking by paying the total bill into an escrow. Should the customer wish to cancel, they would get a full refund unless the cancellation was within a certain range of the booking date, of which 10% would be paid to the supplier as compensation.</p><p>The site appeared to attract the attention of AirBnB, with the site recieving a curious number of visits from San Fransisco as social media announcements were made for the progress. Though ultimately the project was abandoned due to poor financial forecasts and a lack of available time commitment.</p>',

          imagePairings: [

          ],

          // takeaways: [

          // ],

          galleryItems: [
            '/media/portfolio/the-humble-abode/still_large_home.png',
            '/media/portfolio/the-humble-abode/still_large_map.png',
            '/media/portfolio/the-humble-abode/still_large_profile.png',
          ],

          // finalImage: '',
        },
      ],

      testItems: [
        {
          slug: 'example1',
          heroImage: 'https://picsum.photos/1024/960',
          navStyleAlt: false,
          title: 'Example project',
          intro: 'A key point to encourage the user to want to read more. An inviting headline image with an obvious chevron to indicate non-standard navigability.',
          date: 'March 2022',
          type: 'Bespoke app, web',
          role: 'Senior Developer',
          client: 'Example client',

          summaryHtml: "<p>A more detailed overall summary of the project, in one or two small paragraphs. What was the key outcome from the client's perspective?</p> <p>Another line. More direct.</p>",

          headline: 'A catchy, memorable headline for one of the key outcomes.',
          headlineDescriptionHtml: '<p>The problem statement, summarised. The action taken, and the result. Not too punchy, but not too long either.</p> <p>Perhaps some more interesting detail about how things were done, or how the results were measured. A nice image or two would be helpful depending on context.</p>',

          statistics: {
            list1: [
              '150 million SQL queries per week.',
              '2 weeks of development time between a team of three.',
              '53% increase in customer sales.',
            ],
            list2: [
              '23% bounce rate (from 89%).',
              '70% less support queries raised.',
              '34% reduction in hosting costs.',
            ],
          },

          imagePairings: [
            {
              title: 'Oooh',
              descriptionHtml: '<p>Seemingly obligatory Apple product placement to show a screenshot.</p> <p>(It works on everything else too..)</p>',
              image: '/media/portfolio/sample/mbp.png',
              imageIsDevice: true,
            },
            {
              title: 'Yes we thought about mobile',
              descriptionHtml: "<p>and to prove it here's a screenshot from a smartphone placed within a smartphone graphic.</p> <p>Yes, iPhone, but all the other latest phones too.</p>",
              image: '/media/portfolio/sample/iphone5.png',
              imageIsDevice: true,
            },
            {
              title: 'And the sizes in-between',
              descriptionHtml: '<p>May as well complete the set, or better still use this row as a way of highlighting one final point.</p>',
              image: '/media/portfolio/sample/ipad.png',
              imageIsDevice: true,
            },
          ],

          galleryItems: [
            '/media/portfolio/sample/bloom-home-large.png',
            '/media/portfolio/sample/bloom-tech-medium.png',
            '/media/portfolio/sample/bloom-home-large.png',
          ],

          finalImage: '/media/portfolio/sample/all.png',
        },
      ],
    };
  },

  computed: {

    project() {
      return this.items[this.projectIndex];
    },

    countProjects() {
      return this.items.length;
    },

  },

  created() {
  },

  mounted() {
    if (this.$route.params.slug) {
      this.projectIndex = this.slugToIndex(this.$route.params.slug);

      this.navigationState = 1;
      this.moreContainerVisible = true;

      this.$nextTick(() => {
        this.enableTweens();
      });
    }

    this.imageBuffer1Src = this.items[this.projectIndex].heroImage;
  },

  methods: {

    /**
     * If mode = 0, backwards. Mode = 1, forwards.
     */
    navigateProject(mode) {
      // If an animation is already running, don't allow navigation.
      if (this.portfolioTrigger > 0) {
        return;
      }

      let nextIndex = this.projectIndex;
      this.portfolioTriggerDirection = mode;

      if (mode === 0) {
        nextIndex -= 1;
      } else {
        nextIndex += 1;
      }

      if (this.items[nextIndex] === undefined) {
        nextIndex = (mode === 1) ? 0 : this.items.length - 1;
      }

      // Start the fadeOut.
      this.portfolioTrigger = 1;

      this.setHeroImageBuffer(nextIndex);

      // After .5s, swap the project.
      setTimeout(() => {
        this.projectIndex = nextIndex;

        // Start the fadeIn.
        this.$nextTick(() => {
          this.portfolioTrigger = 2;

          // After another 2s, reset the sequence.
          setTimeout(() => { this.portfolioTrigger = 0; }, 1000);
        });
      }, 500);
    },

    /**
     *
     */
    slugToIndex(slug) {
      return this.items.findIndex((item) => item.slug === slug);
    },

    /**
     * Sets either the front or back image buffer src. Swaps the buffers.
     */
    setHeroImageBuffer(nextProjectIndex) {
      if (this.activeImageBuffer === 1) {
        this.imageBuffer2Src = this.items[nextProjectIndex].heroImage;
        this.activeImageBuffer = 2;
      } else {
        this.imageBuffer1Src = this.items[nextProjectIndex].heroImage;
        this.activeImageBuffer = 1;
      }
    },

    /**
     * Enables the various effect bindings.
     */
    enableTweens() {
      if (this.project.listing !== undefined) {
        // eslint-disable-next-line no-unused-vars
        const statisticsIntersectionBinding = new IntersectionBinding({
          triggerEl: '#statistics',
          targets: ['#statsList1', '#statsList2'],
          animationClass: 'final',
          once: true,
          targetRatio: 0.5,
        });
      }

      // eslint-disable-next-line no-unused-vars
      if (this.project.finalImage) {
        // eslint-disable-next-line no-unused-vars
        const finalImageIntersectionBinding = new IntersectionBinding({
          triggerEl: '#final',
          targets: ['#final'],
          animationClass: 'final',
          once: true,
          targetRatio: 0.5,
        });
      }

      let requestId = null;

      let imagePairing1Scene;
      let imagePairing2Scene;
      let imagePairing3Scene;

      if (this.project.imagePairings.length > 0) {
        imagePairing1Scene = new ScrollTween({
          debugName: 'pairing1',
          sceneStartElement: '#imagePairing1',
          sceneDuration: 500,
          sceneTrigger: window.innerHeight / 2 + (window.innerHeight / 4),
          scenes: [
            {
              targetElement: '#imagePairing1 img',
              tweenProperty: { right: '-30vw' },
            },
          ],
        });
      }

      if (this.project.imagePairings.length > 1) {
        // eslint-disable-next-line no-unused-vars
        imagePairing2Scene = new ScrollTween({
          debugName: 'pairing2',
          sceneStartElement: '#imagePairing2',
          sceneDuration: 500,
          sceneTrigger: (window.innerHeight / 2) + (window.innerHeight / 4),
          scenes: [
            {
              targetElement: '#imagePairing2 img',
              tweenProperty: { left: '0' },
            },
            {
              targetElement: '#imagePairing2 span',
              tweenProperty: { opacity: '1' },
            },
          ],
        });
      }

      if (this.project.imagePairings.length > 2) {
        // eslint-disable-next-line no-unused-vars
        imagePairing3Scene = new ScrollTween({
          debugName: 'pairing3',
          sceneStartElement: '#imagePairing3',
          sceneDuration: 500,
          sceneTrigger: (window.innerHeight / 2) + (window.innerHeight / 4),
          scenes: [
            {
              targetElement: '#imagePairing3 img',
              tweenProperty: { right: '-20vw' },
            },
            {
              targetElement: '#imagePairing3 span',
              tweenProperty: { opacity: '1' },
            },
          ],
        });
      }

      // Set timeline time to scrollTop
      function update() {
        if (imagePairing1Scene !== undefined) {
          imagePairing1Scene.update(window.pageYOffset);
        }

        if (imagePairing2Scene !== undefined) {
          imagePairing2Scene.update(window.pageYOffset);
        }

        if (imagePairing3Scene !== undefined) {
          imagePairing3Scene.update(window.pageYOffset);
        }
        requestId = null;
      }

      // Only update on animation frames
      window.addEventListener('scroll', () => {
        if (!requestId) {
          requestId = requestAnimationFrame(update);
        }
      });

      update();
    },

    placeholderImg(w, h) {
      return `https://picsum.photos/${w}/${h}`;
    },

    /**
     * Handle navigation buttons.
     */
    handleNavigate(newNavigationState) {
      this.navigationState = newNavigationState;

      if (newNavigationState === 1) {
        window.history.pushState({}, `Portfolio: ${this.project.title}`, `/portfolio/${this.project.slug}`);
        setTimeout(() => {
          this.moreContainerVisible = true;
          this.$nextTick(() => {
            this.enableTweens();
          });
        }, 1500);
      } else if (newNavigationState === 0) {
        window.history.pushState({}, 'Portfolio listing', '/portfolio');
        this.moreContainerVisible = false;
      }
    },

    showImageModal(image) {
      this.modalImageData.src = image;

      this.modalVisible = true;
    },

  },
};
</script>

<style lang="scss" style="scoped">

  ol{
    li {
      @apply mb-6;
    }
  }

  #navLeft{
    display:none;
    position:absolute;
    top:50vh;
    left:1rem;
    margin-top:-1rem;
    font-size:2rem;
    color:black;
    z-index:1;

    cursor:pointer;

    &.alt{
      svg{
        color:#010101;
      }
    }

    @screen md{
      left:4rem;
      margin-top:-3rem;
      font-size:5rem;
    }

    animation: scrollLeft 1.5s ease-in-out infinite 1s;

    &.visible{
      display:block;
    }
  }

  #navRight{
    display:none;
    position:absolute;
    top:50vh;
    right:1rem;
    margin-top:-1rem;
    font-size:2rem;
    color:#fefefe;
    z-index:1;

    cursor:pointer;

    &.alt{
      svg{
        color:#010101;
      }
    }

    @screen md{
      right:4rem;
      margin-top:-3rem;
      font-size:5rem;
    }

    animation: scrollRight 1.5s ease-in-out infinite 1s;

    &.visible{
      display:block;
    }
  }

  #navDown{
    opacity:0;
    position:absolute;
    bottom:4rem;
    left:50vw;
    margin-left:-1.5rem;
    font-size:3rem;
    z-index:0;
    color:black;

    @screen md{
      bottom:4rem;
      left:25vw;
      margin-left:-3rem;
      font-size:4rem;
    }

    svg{
      //animation: scrollDown 1.5s ease-in-out infinite 1s;
    }

    &.visible {
        opacity:1;

        // Defines transition TO visible.
        transition: opacity 1.5s linear 1.5s;

        z-index:1;
      }

      // Defines transition FROM visible.
      transition: opacity .2s linear 0s;
  }

  .viewport {
    height:100vh;
    width:100vw;
    overflow:hidden;

    &.expanded{
      height:100%;
    }
  }

  .hero {

    position:absolute;
    top:0;
    left: 0;
    height:100vh;
    width:100vw;
    overflow:hidden;

    &.navigated {
      left:0;
    }

    > img.main {
      position:absolute;
      top:0;
      left: -50vw;
      height:100vh;
      width:100vw;
      object-fit:cover;
      border-left:1px solid #e2e8f0;
      border-right:1px solid #e2e8f0;

      transition: left 1.5s ease-in-out 0s;

      &.navigated {
        left:50vw;
      }

      &.trigger-in {
        animation: fadeIn .5s linear 0s;
        animation-fill-mode: forwards;
      }

      &.trigger-out {
        animation: fadeOut .5s linear 0s;
        animation-fill-mode: forwards;
      }
    }

    > .intro {
      @apply bg-white;

      position:absolute;
      top:0;
      right: -100vw;

      width:80vw;
      height:100vh;
      padding:1rem;

      @screen md{
        width:50vw;
        padding:4rem;
      }

      &.visible {
        right:0;

        // Defines transition TO visible.
        transition: right 1.5s ease-in-out .2s;
      }

      // Defines transition TO invisible.
      transition: right 1.5s ease-in-out .5s;

      h1 {
        margin-bottom:3rem;
      }

      button {
        @apply block;
        @apply mx-auto;
        @apply my-4;
      }
    }

    > .detail {
      @apply bg-white;

      position:absolute;
      top:0;
      left: -100vw;

      width:80vw;
      height:100vh;
      padding:1rem;

      @screen md{
        width:50vw;
        padding:4rem;
      }

      &.visible {
        left:0;
        // Defines transition TO visible.
        transition: left 1.5s ease-in-out .2s;
      }

      // Defines transition TO invisible.
      transition: left 1.5s ease-in-out .5s;
    }

  }

  .portfolio-card {

    &.trigger-in {
      animation: fadeIn .5s linear 0s;
    }

    &.trigger-out {
      animation: fadeOut .5s linear 0s;
    }

    &.trigger-in-prev {
      animation: scrollDownFadeIn .5s linear 0s;
    }

    &.trigger-out-prev {
      animation: scrollDownFadeOut .5s linear 0s;
    }

    &.trigger-in-next {
      animation: scrollUpFadeIn .5s linear 0s;
    }

    &.trigger-out-next {
      animation: scrollUpFadeOut .5s linear 0s;
    }

  }

  .more {
    display:none;
    position:absolute;
    overflow:hidden;
    top:100vh;
    left: 0;
    width:100vw;
    min-height:100vh;
    padding-top:12rem;

    &.visible{
      display:block;
    }

  }

  .section--grey {
    ul{
      @apply mt-4;
      @apply mx-0;
    }
  }

  #statsList1{
    opacity: 0;
    transition: opacity 1.5s ease-in-out .2s;

    &.final {
      opacity:1;
      transition: opacity 1.5s ease-in-out .2s;
    }
  }

  #statsList2{
    opacity: 0;
    transition: opacity 1.5s ease-in-out .6s;

    &.final {
      opacity:1;
      transition: opacity 1.5s ease-in-out .2s;
    }
  }

  .image-pairing {
    @apply mx-auto;

    position:relative;
    overflow:visible;
    width:100%;

    >div {
      @apply block;

      @screen md {
        @apply flex;
        @apply items-center;
      }

      min-height:20rem;
    }

    span {
      @apply block;
      @apply w-full;
    }

    img {
      @apply block;
      @apply mt-12;

      @screen md {
        @apply mt-0;
        z-index:-1;
        position:absolute;
      }
    }

    &--mbp {

      > div {
        min-height:30rem;

        @screen md {
          min-height:35rem;
        }
      }

      span {
        @screen md {
          @apply w-1/3;
        }
      }

      img {
        @apply mx-auto;

        @screen md {
          max-width:1024px;

          // ScrollTweened to right:-30vw;
          right:-100vw;
          transform:rotateZ(23deg);
        }

        &.non-device {

          @screen md {
            max-height:75vh;
          }
        }

      }
    }

    &--iphone{

      > div {
        min-height:20rem;

        @screen md {
          min-height:30rem;
        }
      }

      span {
        @screen md {
          @apply w-2/3;
          position:absolute;
          right:0;

          // ScrollTweened.
          opacity:0;
        }
      }

      img {
        @apply mx-auto;

        @screen md {
          max-width:185px;

          // ScrollTweened to left:0;
          left:-100vw;
          transform:rotateZ(-17deg);
        }

        &.non-device {
          @screen md {
            max-width:1024px;
            max-height:57vh;
          }
        }
      }
    }

    &--ipad{

      @apply mb-8;

      > div {
        min-height:20rem;

        @screen md {
          min-height:30rem;
        }
      }

      span {

        @apply bg-white;

        @screen md {
          @apply w-2/3;

          // ScrollTweened.
          opacity:0;
        }
      }

      img {
        @apply mx-auto;

        @screen md {
          max-width:600px;

          // ScrollTweened to right:-20vw;
          right:-100vw;
        }

        &.non-device {
          @screen md {
            max-width:1024px;
            max-height:75vh;
          }
        }
      }
    }
  }

  .section--blue{
  }

  #final {

    z-index:999;

    @screen md {
      //margin-top:-12rem;
      margin-top:0;
    }

    opacity: 0;
    transition: opacity 1.5s ease-in-out .2s;

    &.final {
      opacity:1;
      transition: opacity 1.5s ease-in-out .2s;
    }
  }

  #galleryIcon{
    position:absolute;
    top:2rem;
    right:3rem;
    opacity:.2;
    color:#efefef;
    font-size:12rem;
    transform:rotateZ(23deg);
    z-index:-1;
  }

  @keyframes scrollDown
  {
    0% {
        opacity: 0;
        color:white;
        transform: translate3d(0,0,0);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        color:black;
        transform: translate3d(0,100%,0);
    }
  }

  @keyframes scrollLeft
  {
    0% {
        opacity: .8;
        color:white;
        transform: translate3d(0,0,0);
    }

    50% {
        opacity: 1;
        transform: translate3d(5px,0,0);
    }

    100% {
        opacity: .8;
        color:#fdfdfd;
        transform: translate3d(0,0,0);
    }
  }

  @keyframes scrollRight
  {
    0% {
        opacity: .8;
        color:white;
        transform: translate3d(0,0,0);
    }

    50% {
        opacity: 1;
        transform: translate3d(5px,0,0);
    }

    100% {
        opacity: .8;
        color:#fdfdfd;
        transform: translate3d(0,0,0);
    }
  }

</style>
