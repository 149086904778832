/**
 * IntersectionBinding
 *
 * Wrapper for the intersection observer API.
 *
 * Allows to specify a trigger element, and an array of target elements
 * to add a css class to.
 *
 * triggerEl: Selector for the trigger element.
 * targets: Selectors for the target elements.
 * animationClass: Class which will be toggled to do the animation.
 */
export default class IntersectionBinding {
  constructor(args) {
    this.triggerElSelector = args.triggerEl;
    this.targetSelectors = args.targets;
    this.animationClass = args.animationClass;
    this.once = args.once;
    this.targetRatio = args.targetRatio;
    this.hasRun = false;

    this.triggerEl = document.querySelector(this.triggerElSelector);

    this.observer = new IntersectionObserver((entries) => {
      if (!(this.once && this.hasRun)) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            this.targetSelectors.forEach((selector) => {
              document.querySelector(selector).classList.add(this.animationClass);
            });
            this.hasRun = true;
          } else {
            this.targetSelectors.forEach((selector) => {
              document.querySelector(selector).classList.remove(this.animationClass);
            });
          }
        });
      }
    }, {
      threshold: this.targetRatio,
    });

    this.observer.observe(this.triggerEl);
  }
}
