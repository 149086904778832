<template hidden>
  <div>
    <header class="mx-auto pt-16 max-w-screen-xl">
      <sup class="float-right">{{ post.pubDate }} | {{ category }}</sup>
      <h1>{{ post.title }}</h1>
      <p v-if="post.archived" class="font-bold italic text-center py-6">This is an archived blog entry from {{post.pubDate.substring(0, 4)}} and likely holds little relevance.</p>
    </header>
    <transition name="fade">
      <div v-if="content" v-html="content">
      </div>
    </transition>

    <img class="signed" src="/img/signed.png" alt="signature">

    <modal
      :is-visible="modalVisible"
      :image-data="modalImageData"
      @modal-closed="modalVisible = false"
    />
  </div>
</template>

<script>

import { posts } from '../entries.json';
import modal from '../components/ImageModal.vue';

const axios = require('axios');

export default {
  name: 'BlogPost',
  components: {
    modal,
  },

  title() {
    return `${this.title}`;
  },

  data() {
    return {
      title: '',
      content: '',
      posts,
      post: {},

      modalVisible: false,
      modalImageSrc: '',

      modalImageData: {
        title: '',
        src: '',
        caption: '',
      },
    };
  },

  computed: {
    category() {
      if (this.post.tags !== undefined) {
        return this.post.tags[0];
      }

      return '';
    },
  },

  created() {
    window.IM = this.showModal.bind(this);
  },

  mounted() {
    this.loadPost();
  },

  methods: {

    loadPost() {
      const post = posts.find((element) => element.slug === this.$route.params.slug);
      this.post = post;
      this.title = post.title;
      document.title = post.title;

      // setTimeout(() => {
      axios.get(`/content/posts/${post.src}`)
        .then((response) => {
          let content = response.data;
          content = content.replace(/<img/g, '<img onclick="IM()"');
          this.content = content;
        });
      // }, 4000);
    },

    showModal() {
      // eslint-disable-next-line no-restricted-globals
      if (event.srcElement.dataset.fullSrc) {
        // eslint-disable-next-line no-restricted-globals
        this.modalImageData.src = event.srcElement.dataset.fullSrc;
      } else {
        // eslint-disable-next-line no-restricted-globals
        this.modalImageData.src = event.srcElement.src;
      }

      // eslint-disable-next-line no-restricted-globals
      this.modalImageData.title = event.srcElement.title;

      // eslint-disable-next-line no-restricted-globals
      this.modalImageData.caption = event.srcElement.alt;

      this.modalVisible = true;
    },

  },
};
</script>

<style lang="scss" style="scoped">

</style>
