import '@/assets/css/tailwind.css';
import 'animate.css/source/_base.css';
import 'animate.css/source/bouncing_entrances/bounceInUp.css';
import 'animate.css/source/fading_entrances/fadeIn.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faMobile,
  faQuoteLeft,
  faThumbsDown,
  faThumbsUp,
  faComment,
  faEnvelope,
  faPhone,
  faBars,
  faTimes,
  faCamera,
} from '@fortawesome/free-solid-svg-icons';

import { faSafari } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { createApp, configureCompat } from 'vue';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import titleMixin from './mixins/titleMixin';

const app = createApp(App);

configureCompat({
  MODE: 3,
});

app.mixin(titleMixin);

library.add(
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faMobile,
  faSafari,
  faQuoteLeft,
  faThumbsDown,
  faThumbsUp,
  faComment,
  faEnvelope,
  faPhone,
  faBars,
  faTimes,
  faCamera,
);

app.component('font-awesome-icon', FontAwesomeIcon);

app.use(VueGtag, {
  config: {
    id: 'G-HHZP29WCBC',
  },
  router,
  enabled: process.env.NODE_ENV === 'production',
  // debug: {
  //   sendHitTask: process.env.NODE_ENV === 'production',
  // },
});

// app.config.productionTip = false;

window.snapSaveState = () => {
  document.querySelector('#app').setAttribute('data-server-rendered', 'true');
};

app.use(router);
// app.directive('focus', {
//   mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
// });
app.mount('#app');
