function getTitle(vm) {
  const { title } = vm.$options;
  if (title) {
    return typeof title === 'function'
      ? title.call(vm)
      : title;
  }

  return '';
}

function getMetaCanonical(vm) {
  const { metaCanonical } = vm.$options;
  if (metaCanonical) {
    return typeof metaCanonical === 'function'
      ? metaCanonical.call(vm)
      : metaCanonical;
  }
  return window.location.toString().replace(/\/$/, '');
}

export default {
  created() {
    this.setDocumentTitle();
    this.setMetaCanonical();
  },

  methods: {

    setMetaCanonical() {
      const metaCanonical = getMetaCanonical(this);

      if (metaCanonical) {
        let linkElement = document.getElementById('canonical');

        if (!linkElement) {
          linkElement = document.createElement('link');
          linkElement.id = 'canonical';
          linkElement.rel = 'canonical';
        }

        linkElement.href = metaCanonical;
        document.head.appendChild(linkElement);
      }
    },

    setDocumentTitle() {
      const title = getTitle(this);
      if (title) {
        document.title = `${title} :: jamesedgeworth.com`;
      }
    },

    updateTitle() {
      this.setDocumentTitle();
    },

    updateMetaCanonical() {
      this.setMetaCanonical();
    },
  },

};
