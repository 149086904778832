<template hidden>
  <section v-if="isVisible" @click="close" @keyup="close">
    <article>
      <header>
        <h3>{{ imageData.title }}</h3>
      </header>
      <img :src="imageData.src" alt="" />
      <p class="caption">{{ imageData.caption }}</p>
    </article>
  </section>
</template>

<script>

export default {

  name: 'ImageModal',
  components: {

  },

  props: {
    imageData: {
      type: Object,
      required: false,
      default: undefined,
    },

    isVisible: {
      type: Boolean,
      required: true,
    },
  },

  computed: {

  },

  data() {
    return {
    };
  },

  created() {

  },

  methods: {
    close() {
      this.$emit('modal-closed');
    },
  },
};
</script>
<style lang="scss" scoped>

section{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;

  background:rgba(1, 1, 1, 0.8);

  z-index:999;
}

  article{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    background:white;

    padding:4rem;
  }

</style>
