/**
 * Pin
 *
 * Used to pin an element on screen for a duration.
 *
 * args:
 * duration: Scroll amount (in pixels) in which the element will be pinned.
 * This sets the height of the pin container.
 * containerElementSelector: Selector for the container element.
 * subjectElementSelector: Selector for the element which will be pinned.
 *
 * When the bottom edge of the subject element reaches the bottom of the
 * container element, the subject element is positioned absolute to the
 * bottom of the container element.
 */
export default class Pin {
  constructor(args) {
    this.duration = args.duration;
    this.containerElementSelector = args.container;
    this.subjectElementSelector = args.subject;

    this.containerEl = document.querySelector(this.containerElementSelector);
    this.subjectEl = document.querySelector(this.subjectElementSelector);

    this.containerEl.classList.add('pin--container');
    this.subjectEl.classList.add('pin--subject');
    this.containerEl.style.height = `${this.duration}px`;

    window.addEventListener('scroll', () => {
      const scrollPosition = document.documentElement.scrollTop;
      const edge = Pin.getElementBottomPosition(this.containerEl);
      const detectorEdge = scrollPosition + this.subjectEl.offsetHeight;

      // console.log(detectorEdge);
      // console.log(edge);

      if (detectorEdge >= edge) {
        this.subjectEl.classList.add('pin--subject--unpinned');
      } else {
        this.subjectEl.classList.remove('pin--subject--unpinned');
      }
    });
  }

  /**
   *
   * @param {*} el
   */
  static getElementPosition(el) {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    return {
      top: rect.top + scrollTop,
      left: rect.left + scrollLeft,
    };
  }

  /**
   *
   * @param {*} el
   */
  static getElementBottomPosition(el) {
    const position = this.getElementPosition(el);
    return position.top + el.offsetHeight;
  }
}
