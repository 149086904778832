<template hidden>
  <article class="pt-32">
    <h1>Now</h1>
    <div class="mx-8 md:mx-32 xl:mx-64">
      <sub class="block mb-6">Last updated: 2nd March 2020</sub>
      <ul>
        <li>
          Technical Director of a software-as-a-service start up. Really enjoying the change of pace
          and different challenges from Freelancing and web agency work. Being able to stick with
          the project for the long-term really lets you code the best out of it.
        </li>
        <li>
          Regular Cycling, in particular with a road bike. Need to lose a lot of weight to get
            better at the climbs though - 116kg really limits the conquerable hills.
        </li>
        <li>
          Other bike-related stuff. A friend has setup an e-Bike business, and I was the lucky one
          to give it a month's trial. Also helping with tech-related stuff.
        </li>
        <li>Living in Cardiff.</li>
      </ul>

      <p>"Now" page concept by <a href="https://sivers.org/nowff">Derek Sivers</a></p>
    </div>
  </article>
</template>

<script>

export default {
  name: 'NowPage',
  components: {

  },

  title() {
    return 'Now';
  },

  data() {
    return {
    };
  },
};
</script>
<style lang="scss" scoped>

</style>
